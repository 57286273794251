import React from "react";
import styled from "styled-components";
import { FluidObject } from "gatsby-image";

import { BlocksContent, Image } from "@components/shared/sub";
import { Maybe, SanityWorkHero } from "@graphql-types";
import {
  colors,
  HEADER_HEIGHT,
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
} from "@util/constants";
import { Container } from "@util/standard";

interface Props {
  data: Maybe<SanityWorkHero> | undefined;
}

const AbsoluteContainer = styled(Container)<{
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
}>`
  position: absolute;
  flex-direction: column;
  row-gap: 20px;

  ${({ top }) => top && `top: ${top};`};
  ${({ left }) => left && `left: ${left};`};
  ${({ right }) => right && `right: ${right};`};
  ${({ bottom }) => bottom && `bottom: ${bottom};`};
`;

const ImageWrapper = styled(AbsoluteContainer)<{ hasLogo?: boolean }>`
  width: 30%;
  ${({ hasLogo }) => hasLogo && `width: 29%;`}
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 50%;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 80%;
  }
`;

const TextWrapper = styled.div<{ hasLogo: boolean }>`
  color: ${colors.white};
  h1,
  .heading-1 {
    font-size: 30px;
    line-height: 1;
    color: white;
  }
  h2 {
    font-size: 21px;
  }
`;

const Gradient = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background: linear-gradient(
    245.8deg,
    rgba(0, 0, 0, 0) 40.33%,
    rgba(0, 0, 0, 0.4) 81.37%
  );
`;

function WorkInnerHero({ data }: Props) {
  if (!data) return null;
  const { backgroundImage, headerText, logo } = data;

  return (
    <Container height="100vh" width="100vw">
      <AbsoluteContainer width="100%" height="100%" top="0px" left="0px">
        {backgroundImage && (
          <Image
            height="100%"
            fluid={backgroundImage?.asset?.fluid as FluidObject}
            data={backgroundImage}
            objectFit="cover"
          />
        )}
        <Gradient />
        <ImageWrapper bottom="5%" left="8%" hasLogo={logo != null}>
          {logo && logo?.asset?.url && (
            <Container margin="0" width="267px" mobileWidth="250px">
              <img style={{ width: "100%" }} src={logo.asset?.url} />
            </Container>
          )}
          <TextWrapper hasLogo={logo != null}>
            <BlocksContent blocks={headerText?._rawColumnContent} />
          </TextWrapper>
        </ImageWrapper>
      </AbsoluteContainer>
    </Container>
  );
}

export default WorkInnerHero;
